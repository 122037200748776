import store from '@/store/index.js'
let dic = store.state.dic;
export default function MHandle({
  handleData,
  handleGetPath,
  handleGetPathNoparam,
  handleLoading = false,
  handleInsertPath,
  handleUpdatePath,
  handleDelPath,
  handleDownLoadPath,
} = {}) {
  let data = Object.assign({}, handleData);
  return {
    data() {
      return {
        handleData,
        handleGetPath,
        handleGetPathNoparam,
        handleLoading,
        handleInsertPath,
        handleUpdatePath,
        handleDelPath,
        handleDownLoadPath,
      }
    },
    methods: {
      handleBeforeAfterFn: function () { },
      handleGetAfterFn: function () { },
      handleBeforeFn: function () { },
      handleInitBefore: function () { },
      handleAfterFn: function () {
        this.$router.go(-1);
      },
      handleInit: function () {
        let initData = this.handleInitBefore() || data;
        this.$nextTick(() => {
          this.handleData = Object.assign({}, initData);
        })
      },
      handleGetNoparam() {
        if (!this.handleGetPathNoparam) {
          (process.env.NODE_ENV == 'development') && (
            console.warn("Please set handleGetPath")
          );
          return;
        }
        this.handleLoading = true;
        this.handleData = this.handleInit() || this.handleData;
        //console.log(this.handleGetPathNoparam)
        this.$http.get(this.handleGetPathNoparam).then(res => {
          this.handleLoading = false;
          // if (res.code == dic.SUCCESS_CODE) {
          if (res.success) {
            let data = this.handleGetAfterFn(res.data) || res.data;
            this.$nextTick().then(() => {
              this.handleData = data;
            })
            // console.log(this.handleData)
          } else {
            this.$message.error(
              `获取数据失败，${res.msg}`
            );
          }
        }).catch(err => {
          this.handleLoading = false;
          // console.warn(`获取数据失败，${err}`);
          this.$message.error(
            `获取数据失败，${err}`
          );
        });
      },
      handleGet(params) {
        if (!this.handleGetPath) {
          (process.env.NODE_ENV == 'development') && (
            console.warn("Please set handleGetPath")
          );
          return;
        }
        this.handleLoading = true;
        this.handleData = this.handleInit() || this.handleData;
        this.$http.get(this.handleGetPath + '/' + params).then(res => {
          // console.log(res)
          this.handleLoading = false;
          // if (res.code == dic.SUCCESS_CODE) {
          if (res.success) {
            let data = this.handleGetAfterFn(res.data) || res.data;
            this.$nextTick().then(() => {
              this.handleData = data;
            })
          } else {
            this.$message.error(
              `获取数据失败，${res.msg}`
            );
          }
        }).catch(err => {
          this.handleLoading = false;
          // console.warn(`获取数据失败，${err}`);
          this.$message.error(
            `获取数据失败，${err}`
          );
        });
      },
      handleInsert() {
        if (!this.handleInsertPath) {
          (process.env.NODE_ENV == 'development') && (
            console.warn("Please set handleInsertPath")
          );
          return;
        }
        if (this.handleLoading) {
          return;
        }
        this.handleLoading = true;
        let params = this.handleBeforeFn(this.handleData) || this.handleData;
        console.log(params)
        this.$http.post(this.handleInsertPath, params).then(res => {
          // if (res.code == dic.SUCCESS_CODE) {
          if (res.success) {
            console.log(res)
            this.$message({
              message: "恭喜你，保存成功！",
              type: "success"
            });
            this.handleAfterFn(res);
          } else {
            this.$message.error(
              `保存数据失败，${res.msg}`
            );
          }
          this.handleLoading = false;
        }).catch(err => {
          this.handleLoading = false;
          this.$message.error(
            `保存数据失败，${err}`
          );
        });
      },
      handleUpdate() {
        if (!this.handleUpdatePath) {
          (process.env.NODE_ENV == 'development') && (
            console.warn("Please set handleUpdatePath")
          );
          return;
        }
        this.handleLoading = true;
        let params = this.handleBeforeFn(this.handleData) || this.handleData;
        this.$http.post(this.handleUpdatePath, params).then(res => {
          // if (res.code == dic.SUCCESS_CODE) {
          if (res.success) {
            // console.log(res)
            this.$message({
              message: "恭喜你，修改成功！",
              type: "success"
            });
            this.handleAfterFn(res);
          } else {
            this.$message.error(
              `保存数据失败，${res.msg}`
            );
          }
          this.handleLoading = false;
        }).catch(err => {
          this.handleLoading = false;
          this.$message.error(
            `保存数据失败，${err}`
          );
        });
      },
      handleDel(params) {
        if (!this.handleDelPath) {
          (process.env.NODE_ENV == 'development') && (
            console.warn("Please set handleDelPath")
          );
          return;
        }
        this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true
        }).then(() => {
          this.$http.get(this.handleDelPath, {
            params
          }).then(res => {
            console.log(res)
            // if (res.code == dic.SUCCESS_CODE) {
            if (res.success) {
              this.$message({
                message: "恭喜你，删除成功！",
                type: "success"
              });
              this.handleAfterFn(res);
            } else {

              this.$message.error(
                `删除数据失败，${res.msg}`
              );
            }
          }).catch(err => {
            // console.warn(`获取数据失败，${err}`);
            this.$message.error(
              `删除数据失败，${err}`
            );
          });
        }).catch(err => console.warn(err));
      },
      handleDownLoad(params) {
        if (!this.handleDownLoadPath) {
          (process.env.NODE_ENV == 'development') && (
            console.warn("Please set handleDownLoadPath")
          );
          return;
        }
        this.$http.get(this.handleDownLoadPath, {
          responseType: 'blob',
          params
        }).then(res => {
          // if (res.status === dic.SUCCESS_CODE) {
          if (res.success) {
            const link = document.createElement('a');
            let url = URL.createObjectURL(res.data);
            link.href = url;
            link.download = decodeURIComponent(res.headers['content-disposition'].split('=')[1]);
            link.click();
            URL.revokeObjectURL(url);
            link.remove();
          }
        })
          .catch(err => console.warn(err));
      },
      formatForm(formItems) {
        let o = {}
        formItems.forEach(item => {
          o[item.model] = '';
        })
        console.log(JSON.stringify(o));
      }
    },
  }
}
