import store from '@/store/index.js'
let dic = store.state.dic;
import exportHttp from "@/config/exportHttp.js";

export default function MPage({
  pagePath,
  treePath,
  pageParams = {},
  pageData = [],
  pageLoading = false,
  pageExportPath,
  pageExportParams = {},
  pageDelPath,
  pageDelsPath,
} = {}) {

  return {
    data() {
      return {
        pagePath,
        treePath,
        pageParams: Object.assign({
          pageSize: 10,
          pageNum: 1,
          total: 0,
          currentPage: 0,
        }, pageParams),
        pageData,
        pageLoading,
        pageExportPath,
        pageExportParams,
        pageDelPath,
        pageDelsPath,
      }
    },
    methods: {
      pageGetBeforeFn: function () { },
      pageGetAfterFn: function () { },
      pageExportBeforeFn: function () { },
      pageExportAfterFn: function () { },
      pageDelAfterFn: function () {
        this.pageGet(true);
      },
      pageGet(tag) {
        if (!this.pagePath && !this.treePath) {
          (process.env.NODE_ENV == 'development') && (
            console.warn("Please set pagePath")
          );
          return;
        }
        if (tag === true) {
          this.pageData = [];
          this.pageParams.pageSize = 10;
          this.pageParams.pageNum = 1;
          this.pageParams.total = 0;
          this.pageParams.currentPage = 0;
        }
        let params = this.pageGetBeforeFn(this.pageParams) || this.pageParams;
        // console.log(params)
        this.pageLoading = true;
        if (this.pagePath) {
          this.$http.post(this.pagePath, params).then(res => {
            // console.log(res)
            if (res.success) {
              let data = this.pageGetAfterFn(res.data) || res.data;
              this.pageData = data.list
              this.pageParams.total = data.total;
              this.pageParams.currentPage = data.current;
            } else {
              this.$message.error(
                `获取数据失败，${res.msg}`
              );
            }
            this.pageLoading = false;
          }).catch(err => {
            this.pageLoading = false;
            this.$message.error(
              `获取数据失败，${err}`
            );
          })
        }
        if (this.treePath) {
          this.$http.get(this.treePath).then(res => {
            if (res.success) {
              let data = this.pageGetAfterFn(res.data) || res.data;
              this.pageData = data
            } else {
              this.$message.error(
                `获取数据失败，${res.msg}`
              );
            }
            this.pageLoading = false;
          }).catch(err => {
            this.pageLoading = false;
            this.$message.error(
              `获取数据失败，${err}`
            );
          })
        }
      },
      pageDel(params, title = "此操作将永久删除该数据, 是否继续?") {
        if (!this.pageDelPath) {
          (process.env.NODE_ENV == 'development') && (
            console.warn("Please set pageDelPath")
          );
          return;
        }
        this.$confirm(title, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true
        }).then(() => {
          this.$http.get(this.pageDelPath, { params: { id: params } }).then(res => {
            if (res.success) {
              this.$message({
                message: "删除成功！",
                type: "success"
              });
              this.pageDelAfterFn(res);
            } else {
              this.$message.error(
                `删除数据失败，${res.msg}`
              );
            }
          }).catch(err => {
            this.$message.error(
              `删除数据失败，${err}`
            );
          });
        }).catch(err => console.warn(err));
      },
      pageDels(params) {
        if (!this.pageDelsPath) {
          (process.env.NODE_ENV == 'development') && (
            console.warn("Please set pageDelsPath")
          );
          return;
        }
        this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true
        }).then(() => {
          this.$http.post(this.pageDelsPath, params).then(res => {
            if (res.code === dic.SUCCESS_CODE) {
              this.$message({
                message: "恭喜你，删除成功！",
                type: "success"
              });
              this.pageDelAfterFn(res);
            } else {
              this.$message.error(
                `删除数据失败，${res.msg}`
              );
            }
          }).catch(err => {
            console.warn(`获取数据失败，${err}`);
            this.$message.error(
              `删除数据失败，${err}`
            );
          });
        }).catch(err => console.warn(err));
      },
      pageImport() {

      },
      // pageExport(filename) {
      //   if (!this.pageExportPath) {
      //     (process.env.NODE_ENV == 'development') && (
      //       console.error("Please set pageExportPath")
      //     );
      //     return
      //   }
      //   let params = this.pageExportBeforeFn() || this.pageExportParams;
      //   this.$http.get(this.pageExportPath, {
      //     responseType: 'blob',
      //     params
      //   }).then(res => {
      //     const link = document.createElement('a');
      //     let url = URL.createObjectURL(res);
      //     link.href = url;
      //     //link.download = decodeURIComponent(res.headers['content-disposition'].split('=')[1]);
      //     link.download = filename ? filename : '导出数据.xls';
      //     link.click();
      //     URL.revokeObjectURL(url);
      //     link.remove();
      //     this.pageExportAfterFn()
      //   }).catch(err => console.warn(err));
      // },
      pageExport() {
        if (!this.pageExportPath) {
          (process.env.NODE_ENV == 'development') && (
            console.error("Please set pageExportPath")
          );
          return
        }
        let params = this.pageExportBeforeFn() || this.pageExportParams;
        exportHttp.post(this.pageExportPath, params, {
          responseType: 'blob',

        }).then(res => {
          // console.log(res)
          const link = document.createElement('a');
          let url = URL.createObjectURL(res.data);
          link.href = url;
          link.download = decodeURIComponent(res.headers['content-disposition'].split('=')[1]);
          // link.download = filename ? filename : '导出数据.xls';
          link.click();
          URL.revokeObjectURL(url);
          link.remove();
          this.pageExportAfterFn()
        }).catch(err => console.warn(err));
      },
    },
    mounted() {
      this.pageGet(true)
    }
  };
}
