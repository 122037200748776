/*
axios 配置
 */
import axios from 'axios';
import router from '../router';
import {
    Loading
} from 'element-ui';
// axios.defaults.baseURL = '';

const service = axios.create({
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json'
    }
})

service.interceptors.request.use(
    config => {
        let token = localStorage.getItem('bridge-token');
        if (token) {
            config.headers.token = token;
        } else {
            router.push({
                path: '/login'
            })
        }
        let url = localStorage.getItem('baseUrl');
        if (!config.url.startsWith('/bridge') && !config.url.startsWith('/cas')) config.url = url + config.url;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)

service.interceptors.response.use(

    response => {
        // return Promise.resolve(response.data);
        return Promise.resolve(response);
    },
    error => {
        if (error && error.response) {
            // 根据错误码跳转到对应的页面
            switch (error.response.status) {
                case 401:
                    router.push('/error/401')
                    break;
                // case 404:
                //     router.push('/error/404')
                //     break;
                // case 500:
                //     router.push('/error/500')
                //     break;
                case 403:
                    localStorage.removeItem("token");
                    localStorage.removeItem("userInfo");
                    console.log("登录页")
                    router.push("/login");
                    break;
                default:
                    return Promise.reject(error);
            }
        } else {
            return Promise.reject(error);
        }
        let loading = Loading.service({ fullscreen: true })
        loading.close();
    }
)

export default service;
